/* Enables customization of addons */
/* Helper to load variables */
#sidebar .image-wrapper {
  position: relative;
  height: 100px;
  margin-top: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}
#sidebar .image-wrapper .ui.button.remove-image {
  position: absolute;
  top: 7px;
  right: 7px;
  margin: 0;
  box-shadow: none;
}
#sidebar .image-wrapper .ui.button.remove-image:hover {
  background-color: transparent !important;
}
#sidebar .image-wrapper .ui.button.remove-image svg {
  padding: 2px;
  border: 1px solid #e4e8ec;
  margin: 0;
  background: #f3f5f7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  opacity: 1;
}
#sidebar .align-widget .eight.wide.column > div {
  display: flex !important;
  align-items: center;
}
#sidebar .align-widget .ui.icon.button {
  padding: 4px !important;
  margin-left: 4px !important;
  border-radius: 1px !important;
}
#sidebar .align-widget .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
#sidebar .align-widget .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
#sidebar .align-widget .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.view-viewview.has-fullscreenSlideshow .slider-wrapper,
.view-editview.has-fullscreenSlideshow .slider-wrapper {
  padding-top: 120px !important;
}
.view-viewview.has-fullscreenSlideshow .ui.segment.content-area,
.view-editview.has-fullscreenSlideshow .ui.segment.content-area {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.view-viewview.has-fullscreenSlideshow .slider-arrow,
.view-editview.has-fullscreenSlideshow .slider-arrow {
  bottom: 67%;
}
.view-viewview.has-fullscreenSlideshow .slider-arrow .left-arrow,
.view-editview.has-fullscreenSlideshow .slider-arrow .left-arrow {
  left: 0px !important;
}
.view-viewview.has-fullscreenSlideshow .slider-arrow .right-arrow,
.view-editview.has-fullscreenSlideshow .slider-arrow .right-arrow {
  right: 0px !important;
}
.view-viewview.has-fullscreenSlideshow .documentFirstHeading,
.view-editview.has-fullscreenSlideshow .documentFirstHeading,
.view-viewview.has-fullscreenSlideshow #footer,
.view-editview.has-fullscreenSlideshow #footer,
.view-viewview.has-fullscreenSlideshow #page-document p,
.view-editview.has-fullscreenSlideshow #page-document p {
  display: none !important;
}
.view-viewview.has-fullscreenSlideshow #main,
.view-editview.has-fullscreenSlideshow #main {
  height: 100% !important;
}
.view-viewview.has-fullscreenSlideshow .imagecards-block,
.view-editview.has-fullscreenSlideshow .imagecards-block,
.view-viewview.has-fullscreenSlideshow .slider-wrapper,
.view-editview.has-fullscreenSlideshow .slider-wrapper,
.view-viewview.has-fullscreenSlideshow .slider-slide .slide-img,
.view-editview.has-fullscreenSlideshow .slider-slide .slide-img {
  height: calc(100vh) !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.view-viewview.has-fullscreenSlideshow .slider-wrapper .slider-slide .slider-caption,
.view-editview.has-fullscreenSlideshow .slider-wrapper .slider-slide .slider-caption {
  position: absolute;
  bottom: 120px;
}
@media only screen and (max-width: 768px) {
  .ui.cards.two .card,
  .ui.cards.three .card,
  .ui.cards.four .card {
    width: calc(100% - 2rem) !important;
  }
}
