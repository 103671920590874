.grid-teaser-item {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}
.grid-teaser-item.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid-teaser-item.placeholder img {
  max-width: 300px;
  height: auto;
  align-self: center;
}
.grid-teaser-item p {
  margin-bottom: 0;
  text-align: left;
}
.grid-teaser-item.default .grid-image-wrapper {
  margin-right: 25px;
}
.grid-teaser-item.default .grid-image-wrapper img {
  max-width: 100%;
  height: auto;
}
.grid-teaser-item.default .content {
  width: 100%;
}
.grid-teaser-item .grid-image-wrapper img {
  width: 100%;
  aspect-ratio: var(--teaser-images-aspect-ratio, 1.77777778);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--teaser-images-object-position, top left);
     object-position: var(--teaser-images-object-position, top left);
}
#page-document > .block.teaser {
  padding: 25px 0;
}
#page-document > .block.teaser.has--align--left .grid-teaser-item.default,
#page-document > .block.teaser.has--align--right .grid-teaser-item.default {
  display: flex;
}
#page-document > .block.teaser.has--align--left .grid-teaser-item.default .grid-image-wrapper,
#page-document > .block.teaser.has--align--right .grid-teaser-item.default .grid-image-wrapper {
  align-self: flex-start;
}
@media only screen and (max-width: var(--largest-mobile-screen, 767px)) {
  #page-document > .block.teaser.has--align--left .grid-teaser-item.default,
  #page-document > .block.teaser.has--align--right .grid-teaser-item.default {
    flex-direction: column !important;
  }
  #page-document > .block.teaser.has--align--left .grid-teaser-item.default .grid-image-wrapper,
  #page-document > .block.teaser.has--align--right .grid-teaser-item.default .grid-image-wrapper {
    margin-right: 0;
    margin-bottom: 25px;
    margin-left: 0;
  }
  #page-document > .block.teaser.has--align--left .grid-teaser-item.default .grid-image-wrapper img,
  #page-document > .block.teaser.has--align--right .grid-teaser-item.default .grid-image-wrapper img {
    width: 100%;
    max-width: unset;
    height: auto;
  }
}
#page-document > .block.teaser.has--align--left .grid-teaser-item.default {
  flex-direction: row;
}
#page-document > .block.teaser.has--align--left .grid-teaser-item.default .grid-image-wrapper {
  margin-right: 20px;
  margin-left: 0;
}
@media only screen and (max-width: var(--largest-mobile-screen, 767px)) {
  #page-document > .block.teaser.has--align--left .grid-teaser-item.default .grid-image-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}
#page-document > .block.teaser.has--align--right a .grid-teaser-item.default {
  flex-direction: row-reverse;
}
#page-document > .block.teaser.has--align--right a .grid-teaser-item.default .grid-image-wrapper {
  margin-right: 0;
  margin-left: 20px;
}
@media only screen and (max-width: var(--largest-mobile-screen, 767px)) {
  #page-document > .block.teaser.has--align--right a .grid-teaser-item.default .grid-image-wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}
#page-document > .block.teaser.has--align--center .grid-teaser-item.default {
  display: block;
}
#page-document > .block.teaser.has--align--center .grid-teaser-item.default a {
  display: block;
}
#page-document > .block.teaser.has--align--center .grid-teaser-item.default .grid-image-wrapper {
  margin-bottom: 25px;
}
.block-editor-teaser.has--align--left .block.teaser .grid-teaser-item.default,
.block-editor-teaser.has--align--right .block.teaser .grid-teaser-item.default {
  display: flex;
}
.block-editor-teaser.has--align--left .block.teaser .grid-teaser-item.default .grid-image-wrapper,
.block-editor-teaser.has--align--right .block.teaser .grid-teaser-item.default .grid-image-wrapper {
  align-self: flex-start;
}
.block-editor-teaser.has--align--left .block.teaser .grid-teaser-item.default {
  flex-direction: row;
}
.block-editor-teaser.has--align--left .block.teaser .grid-teaser-item.default .grid-image-wrapper {
  margin-right: 20px;
  margin-left: 0;
}
.view-viewview .block-editor-teaser.has--align--right .block.teaser .grid-teaser-item.default {
  flex-direction: row-reverse;
}
.view-viewview .block-editor-teaser.has--align--right .block.teaser .grid-teaser-item.default .grid-image-wrapper {
  margin-right: 0;
  margin-left: 20px;
}
.view-viewview .block-editor-teaser.has--align--center .block.teaser .grid-teaser-item.default {
  display: block;
}
.view-viewview .block-editor-teaser.has--align--center .block.teaser .grid-teaser-item.default .grid-image-wrapper {
  margin: 0 0 25px 0;
}
.block-editor-__grid {
  position: relative;
}
.renderer-wrapper {
  position: relative;
  height: 100%;
  border-radius: 6px;
}
.renderer-wrapper.empty {
  background: #e2f1fd;
}
.renderer-wrapper.selected::before {
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 1px solid rgba(120, 192, 215, 0.5);
  border-radius: 3px;
  content: '';
}
.renderer-wrapper .block {
  margin: 0;
}
.renderer-wrapper .ui.basic.button.remove-block-button {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 2px;
}
.renderer-wrapper .ui.basic.button.remove-block-button .icon {
  height: 18px !important;
}
.renderer-wrapper .ui.basic.button.remove-block-button .circled {
  padding: 8px;
  border: 1px solid #e4e8ec;
  background: #f3f5f7;
}
.renderer-wrapper .ui.basic.button.remove-block-button .circled:hover {
  background-color: #e4e8ec;
}
.renderer-wrapper .ui.basic.button.remove-block-button:hover {
  background-color: transparent !important;
}
.uber-grid-default-item {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uber-grid-default-item .ui.basic.button.add-block-button .icon {
  height: 32px !important;
}
.uber-grid-default-item .ui.basic.button.add-block-button .circled {
  padding: 10px;
  border: none;
  background: #f3f5f7;
}
.uber-grid-default-item .ui.basic.button.add-block-button .circled:hover {
  background-color: #e4e8ec;
}
.uber-grid-default-item .ui.basic.button.add-block-button:hover {
  background-color: transparent !important;
}
.uber-grid-default-item .blocks-chooser:not(.new-add-block) {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.block .block.detached:hover::before {
  border: none;
}
.block [data-rbd-draggable-context-id] {
  margin-bottom: 0;
}
.block.__grid .ui.grid,
[class$='Grid'].block .ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.block-editor-__grid .block .toolbar,
[class$='Grid'] .block .toolbar {
  top: -49px;
  left: -10px;
  border-radius: 6px;
  box-shadow: 0px 9px 18px rgba(2, 19, 34, 0.18), 0px 6px 12px rgba(2, 19, 34, 0.06);
  transform: initial;
}
[class$='Grid'] .block h2:not(.headline),
.block.__grid h2:not(.headline),
[class$='Grid'] .block h3:not(.headline),
.block.__grid h3:not(.headline) {
  margin: 0 !important;
}
[class$='Grid'] .block .grid-teaser-item h2,
.block.__grid .grid-teaser-item h2,
[class$='Grid'] .block .grid-teaser-item h3,
.block.__grid .grid-teaser-item h3 {
  margin-bottom: 1rem !important;
}
[class$='Grid'] .block .grid-image-wrapper,
.block.__grid .grid-image-wrapper {
  display: flex;
  align-items: flex-start;
}
[class$='Grid'] .block .ui.message,
.block.__grid .ui.message {
  margin: 0;
  border-radius: 6px;
}
[class$='Grid'] .block .block.image:hover::before,
.block.__grid .block.image:hover::before {
  display: none;
}
[class$='Grid'] .block .block.listing:hover::before,
.block.__grid .block.listing:hover::before {
  display: none;
}
.block.__grid .grid-teaser-item,
.block.teaserGrid .grid-teaser-item {
  flex-direction: column;
}
.block.__grid .grid-teaser-item.default .grid-image-wrapper,
.block.teaserGrid .grid-teaser-item.default .grid-image-wrapper {
  width: 100%;
  margin-right: 0;
  margin-bottom: 25px;
}
.grid-teaser-item {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}
.grid-teaser-item.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid-teaser-item.placeholder img {
  max-width: 300px;
  height: auto;
  align-self: center;
}
.grid-teaser-item p {
  margin-bottom: 0;
  text-align: left;
}
.grid-teaser-item.default .grid-image-wrapper {
  margin-right: 25px;
}
.grid-teaser-item.default .grid-image-wrapper img {
  max-width: 100%;
  height: auto;
}
.ui.button.template-chooser-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.ui.button.template-chooser-item .template-chooser-title {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
[data-rbd-draggable-context-id].grid-block-slate {
  cursor: text;
}
[data-rbd-draggable-context-id].grid-block-slate .renderer-wrapper {
  min-height: 50px;
}
.grid-image-wrapper img {
  width: 100%;
  aspect-ratio: var(--grid-images-aspect-ratio, 1.77777778);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--grid-images-object-position, top left);
     object-position: var(--grid-images-object-position, top left);
}
.grid-teaser-item.default .content {
  width: 100%;
}
.block.__grid [class^='column grid-block-'] .block:not(.inner)::before,
[class$='Grid'].block [class^='column grid-block-'] .block:not(.inner)::before {
  content: unset;
}
